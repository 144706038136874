<template>
    <BT-Blade-Items
        addBladeName="courier-movement"
        bladeName="courier-movements"
        :bladesData="bladesData"
        canDelete
        :getParams="item => { return { includeDetails: false, customerIDs: item.data == null ? null : item.data.customerID } }"
        :headers="[
            { text: 'Name', value: 'bundleName' },
            { text: 'Due On', value: 'dueArrivalOn', display: true },
            { text: 'From', value: 'departureLocation', textFilter: 'toFamiliarLocationLine' },
            { text: 'To', value: 'destinationLocation', display: true, textFilter: 'toFamiliarLocationLine' },
            { text: 'Journey', value: 'journey', display: true },
            { text: 'Status', value: 'status', display: true }]"
        navigation="courier-movements"
        title="Movements"
        useServerPagination>
        <template v-slot:settings>
            <BT-Entity
                v-if="$canView('courier-settings')"
                ignoreID
                inline
                navigation="courier-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-divider />
                    <v-subheader>Actions <v-spacer />
                        <v-btn small icon :to="{ name: 'courier-actions' }" title="View Past Actions">
                            <v-icon small>mdi-history</v-icon>
                        </v-btn>
                    </v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Auto Deliver Movements</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu-Automation v-model="item.autoDeliverAutomation" @change="save" />
                                <v-btn icon small @click="deliverMovements" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider />
                    <v-subheader>Settings</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Fulfill Movements</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu-Automation v-model="item.fulfillMovementAutomation" @change="save" />
                                <BT-Toggle-Trigger-Icon
                                        v-model="item.fulfillMovementTrigger"
                                        @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Assigning To Journeys</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn  small icon :to="{ name: 'journey-templates' }" title="Manage Journey Templates & Coverage">
                                    <v-icon small>mdi-routes</v-icon>
                                </v-btn>
                                <BT-Toggle-Trigger-Icon
                                    v-model="item.journeyAssignmentTrigger"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <!-- <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Regular Movements</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <BT-Menu-Automation v-model="item.regularMovementAutomation" @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Regular Journeys</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu-Automation v-model="item.regularJourneyAutomation" @change="save" />
                                <BT-Toggle-Trigger-Icon
                                        v-model="item.regularJourneyTrigger"
                                        @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item> -->
                </template>
            </BT-Entity>
        </template>
        <template v-slot:destinationLocation="{ item }">
            <strong>{{ item.isPickup ? '*Pickup ' : '' }}</strong> {{ item.destinationLocation || item.requestedDestinationLocation | toFamiliarLocationLine }}
        </template>
        <template slot="dueArrivalOn" slot-scope="{ item }">
            {{ item.deliveredOn != null ? item.deliveredOn : item.dueArrivalOn | toDayDate }}
        </template>
        <template v-slot:journey="{ item }">
            <strong v-if="item.isPickup">*Pickup</strong>
            <BT-Entity
                v-else
                itemText="journeyName"
                :itemValue="item.journeyID"
                navigation="journeys">
                <template v-slot:prepend>
                    <BT-Btn
                        v-if="item.journeyID != null"
                        @click="unselectJourney(item)"
                        icon="mdi-close"
                        class="error--text ma-0 pa-0" />
                </template>
                <template slot="alternate">
                    <BT-Select-Dialog
                        v-if="!item.isPickup"
                        @change="journey => { selectJourney(item, journey); }"
                        icon="mdi-magnify"
                        navigation="journeys"
                        :onFilter="filterJourneys"
                        :params="{ includeDetails: false, filterBy: 'Not Ended' }"
                        returnObject
                        small
                        title="Assign To Journey"
                        width="300">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.dueStartOn | toShortDate }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>{{ item.startedOn != null ? 'Started' : item.isReady ? 'Ready' : 'Not Ready' }}</v-list-item-action-text>
                            </v-list-item-action>
                        </template>
                    </BT-Select-Dialog>
                    <BT-Select-Dialog
                        v-if="!item.isPickup"
                        @change="journey => { selectJourneySuggestions(item, journey); }"
                        icon="mdi-crosshairs-question"
                        isSingle
                        :itemID="item.id"
                        navigation="journey-suggestions"
                        :onFilter="filterJourneys"
                        :onPullSuccessAsync="res => { return res != null ? [res] : [] }"
                        returnObject
                        small
                        title="Suggest Journey"
                        width="300">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.dueStartOn | toShortDate }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                {{ !item.isCreated ? '*NEW' : '' }}
                            </v-list-item-action>
                        </template>
                    </BT-Select-Dialog>
                </template>
            </BT-Entity>
        </template>
        <template slot="status" slot-scope="{ item }">
            <div v-if="item.isAutoDeliver">(Auto)</div>
            <div v-else-if="item.isFulfilled">Complete</div>
            <div v-else-if="!item.isPickup && item.deliveredOn != null">Delivered</div>
            <div v-else-if="item.releasedOn != null">{{ item.isPickup ? 'Picked Up' : 'On Its Way' }}</div>
            <div v-else-if="item.isPickup">Ready</div>
            <div v-else-if="item.journeyID != null">Pending</div>
            <div v-else>Unassigned</div>
        </template>
        <template v-slot:itemActions="{ item, items }">
            <!-- <BT-Btn
                v-if="item.sellerID == item.courierID && item.releasedOn == null && item.deliveredOn == null && !item.isPickup"
                @click="undoRelease(item)"
                icon="mdi-undo"
                :loading="item.loadingCount > 0"
                title="Undo Release" /> -->
            <BT-Btn
                v-if="item.sellerID == item.courierID && item.releasedOn == null"
                @click="release(item)"
                icon="mdi-clipboard-arrow-right-outline"
                :loading="item.loadingCount > 0"
                title="Manually Release" />
            <BT-Btn
                v-else-if="item.deliveredOn == null && !item.isPickup"
                @click="deliver(item, items)"
                icon="mdi-truck-delivery-outline"
                :loading="item.loadingCount > 0"
                title="Manually Deliver" />
            <BT-Btn
                v-else-if="!item.isFulfilled"
                @click="fulfill(item, items)"
                icon="mdi-check-circle-outline"
                :loading="item.loadingCount > 0"
                title="Manually Fulfill" />
            
            <BT-Select-List-Dialog
                v-if="item.releasedOn == null && !item.isPickup"
                @ok="list => { merge(item, list, items) }"
                icon="mdi-merge"
                label="Merge With"
                :loading="item.loadingCount > 0"
                navigation="courier-movements"
                :onFilter="list => { return list.filter(x => x.id != item.id) }"
                :params="{ bundleID: item.bundleID, departureLocationID: item.departureLocationID, destinationLocationID: item.destinationLocationID }"
                returnObject
                small
                title="Merge With"
                width="500">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-subtitle>From: {{ item.departureLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                        <v-list-item-subtitle>To: {{ item.destinationLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ item.dueArrivalOn | toDayDate }}</v-list-item-action-text>
                    </v-list-item-action>
                </template>
            </BT-Select-List-Dialog>
        </template>
    </BT-Blade-Items>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Movements-Blade',
    components: {
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTSelectListDialog: () => import('~components/BT-Select-List-Dialog.vue'),
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
        BTMenuAutomation: () => import('~components/BT-Menu-Automation.vue'),
    },
    props: {
        bladesData: null
    },
    methods: {
        async deliver(item, fList) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var res = await this.$BlitzIt.store.patch('deliveries', { id: item.id, rowVersion: item.rowVersion, deliverAsIs: true });
                item.rowVersion = res.rowVersion;
                item.proofOfDelivery = res.proofOfDelivery;
                item.deliveredOn = res.deliveredOn;

                if (item.isAutoDeliver) {
                    //remove
                    this.$BlitzIt.store.deleteLocal('courier-movements', item.id);
                    var ind = fList.findIndex(x => x.id == item.id);
                    if (ind >= 0) {
                        fList.splice(ind, 1);
                    }
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        async deliverMovements() {
            this.isLoading = true;

            try {
                await this.$BlitzIt.store.post('courier-actions', { functionName: 'AutoDeliver' });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        filterJourneys(list) {
            return list.filter(y => y.endedOn == null).sort(
                firstBy(x => x.dueStartOn)
                .thenBy(x => x.isReady)
                .thenBy(x => x.destinationCount)
            );
        },
        async fulfill(item, fList) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                await this.$BlitzIt.store.patch('courier-movements', { id: item.id, rowVersion: item.rowVersion, isFulfilledRequest: true });
                
                item.isFulfilled = true;
                this.$BlitzIt.store.deleteLocal('courier-movements', item.id);
                var ind = fList.findIndex(x => x.id == item.id);
                if (ind >= 0) {
                    fList.splice(ind, 1);
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        fulfillMovement(item) {
            item.loading = true;
            this.$BlitzIt.store.patch(
                'courier-movements',
                {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    isFulfilledRequest: true
                }
            )
            .then(() => {
                item.isFulfilled = true;
            })
            .finally(() => {
                item.loading = false;
            })
        },
        async merge(item, movements, list) {
            if (!this.isLengthyArray(movements)) {
                return;
            }
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                await this.$BlitzIt.store.patch('courier-movements', { id: item.id, rowVersion: item.rowVersion, gatherMovementIDs: movements.map(x => x.id) });
                movements.forEach(m => {
                    this.$BlitzIt.store.deleteLocal('courier-movements', m.id, null);
                    var ind = list.findIndex(x => x.id == m.id);
                    if (ind >= 0) {
                        list.splice(ind, 1);
                    }
                })
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async release(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var res = await this.$BlitzIt.store.patch('releases', { id: item.id, rowVersion: item.rowVersion, releaseAsIs: true });
                item.rowVersion = res.rowVersion;
                item.proofOfRelease = res.proofOfRelease;
                item.releasedOn = res.releasedOn;

            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        async selectJourney(movement, journey) {
            if (movement.journeyID == null && journey != null) {
                try {
                    movement.loadingCount += 1;
                    this.$forceUpdate();

                    var data = this.copyDeep(movement);
                    data.journeyID = journey.id;
                    var res = await this.$BlitzIt.store.patch('courier-movements', data);
                    movement.journeyID = res.journeyID;
                    movement.rowVersion = res.rowVersion;
                }
                catch (err) {
                    movement.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    movement.loadingCount -= 1;
                    this.$forceUpdate();
                }
            }
        },
        async selectJourneySuggestions(movement, journey) {
            if (movement.journeyID == null && journey != null) {
                try {
                    movement.loadingCount += 1;
                    this.$forceUpdate();

                    var data = this.copyDeep(movement);
                    if (!journey.isCreated) {
                        //create the journey
                        var jRes = await this.$BlitzIt.store.post('journeys', journey);
                        this.$BlitzIt.store.deleteLocal('journey-suggestions', journey.journeyID);
                        data.journeyID = jRes.id;
                    }
                    else {
                        data.journeyID = journey.journeyID;
                    }
console.log('j suggest');
                    var res = await this.$BlitzIt.store.patch('courier-movements', data);
                    movement.journeyID = res.journeyID;
                    movement.rowVersion = res.rowVersion;
                }
                catch (err) {
                    movement.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    movement.loadingCount -= 1;
                    this.$forceUpdate();
                }
            }
        },
        async unselectJourney(movement) {
            if (movement.journeyID != null) {
                try {
                    movement.loadingCount += 1;
                    this.$forceUpdate();

                    movement.journeyID = null;
                    var res = await this.$BlitzIt.store.patch('courier-movements', movement);
                    movement.rowVersion = res.rowVersion;
                }
                catch (err) {
                    movement.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    movement.loadingCount -= 1;
                    this.$forceUpdate();
                }
            }
        },
    }
}
</script>